import React from "react"
import Layout from "../components/layout"

const About = () => {
	return (
		<Layout>
			<h1>About this webapp</h1>
			<p>
				Research reflects that people who are diagnosed with lupus and treated
				early in the disease process; and those who are informed and active
				participants in their lupus treatment and management plans do better in
				the long term.
			</p>
			<p>
				If you are affected by lupus, then this one-stop{" "}
				<b>
					<em>Be Lupus Wise</em>
				</b>{" "}
				webapp is a great tool to help you to better navigate the diagnosis,
				treatment and management of your own lupus journeys; and to have
				informed consultations with your treating medical teams.
			</p>
			<p>
				This comprehensive webapp was developed by people living with lupus from
				our own Lupus WA community in consultation with medical experts.
			</p>
			<h2>Development</h2>
			<ul>
				<li>
					This web application was developed by{" "}
					<a
						href="https://www.lupuswa.com.au/"
						rel="noopener noreferrer"
						target="_blank"
						className="link"
					>
						Lupus WA
					</a>{" "}
					with grant funding kindly provided by{" "}
					<a
						href="https://connectgroups.org.au/"
						rel="noopener noreferrer"
						target="_blank"
						className="link"
					>
						Connect Groups
					</a>
					.
				</li>
				<li>
					The technical development was provided by{" "}
					<a
						href="https://www.infobite.com.au/"
						rel="noopener noreferrer"
						target="_blank"
						className="link"
					>
						Infobite
					</a>
					.
				</li>
			</ul>
			<br />
			<p>
				<b>Disclaimer:</b>
			</p>
			<p>
				This webapp is provided for information and education purposes only. It
				is not a substitute for medical care. Users should note that over time
				currency and completeness of information may change. All users should
				seek advice from their qualified treating healthcare professionals for
				diagnosis, treatment and management of their condition and for answers
				for their medical questions.
			</p>
		</Layout>
	)
}

export default About
